import React, { ReactElement } from "react";
import uniqid from "uniqid";

import BlockComponentSelector from "components/BlockComponentSelector";
import { BodyM } from "style/components/Typography";
import { EpiContentBlock } from "types/EpiBlock";
import EpiFragment from "types/EpiFragment";
import { EpiFragmentType } from "types/EpiFragmentType";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import RichTextProps from "./RichTextProps";

const generateContent = (
    content: EpiFragment[],
    renderElement: ReactElement,
    theme: string | undefined,
    contentTheme: string | undefined,
    actionTheme: string | undefined,
    lightbox: boolean,
    l18n:
        | {
              closeLabel?: string;
              openImageFullsizeLabel?: string;
          }
        | undefined,
): (string | JSX.Element | undefined)[] => {
    const result = content.reduce(
        (result, nextFragment) => {
            if (
                nextFragment.type === EpiFragmentType.EmbeddedContent ||
                nextFragment.type === EpiFragmentType.Image
            ) {
                let componentProps = nextFragment.value;

                if (typeof nextFragment.value === "object") {
                    componentProps = {
                        ...nextFragment.value,
                        theme: theme as
                            | "lightgray"
                            | "blue"
                            | "cyan"
                            | "coral"
                            | "black",
                        contentTheme: contentTheme,
                        actionTheme: actionTheme,
                        lightbox: lightbox,
                        className: "embedded",
                        l18n: l18n,
                    };
                }

                return [
                    ...result,
                    generateComponent(componentProps as EpiContentBlock),
                ];
            } else if (
                nextFragment.type === EpiFragmentType.Static ||
                nextFragment.type === EpiFragmentType.Url
            ) {
                const lastElement = result.pop();
                let nextFragmentValue = nextFragment.value as string;
                nextFragmentValue = nextFragmentValue
                    .replace(/<table/g, '<div class="responsive-table"><table')
                    .replace(/<\/table>/g, "</table></div>");

                return typeof lastElement === "string"
                    ? [...result, lastElement.concat(nextFragmentValue)]
                    : [...result, lastElement, nextFragmentValue];
            } else {
                return result;
            }
        },
        [] as (string | JSX.Element | undefined)[],
    );

    return convertStringsToJSX(result, renderElement);
};

const generateComponent = (
    embeddedContent: EpiContentBlock,
): string | JSX.Element => {
    return embeddedContent !== undefined &&
        embeddedContent.component !== undefined ? (
        <BlockComponentSelector
            key={uniqid()}
            content={embeddedContent}
            componentSelector={getComponentTypeForContent}
        />
    ) : (
        ("" as string)
    );
};

const convertStringsToJSX = (
    content: (string | JSX.Element | undefined)[],
    renderElement: ReactElement = <BodyM />,
): JSX.Element[] => {
    return content.reduce((result, nextItem, index) => {
        if (nextItem === undefined) return result;
        else if (typeof nextItem === "string") {
            if (nextItem.trim() === "") return result;
            return [
                ...result,
                React.cloneElement(renderElement, {
                    as: "div",
                    key: index,
                    dangerouslySetInnerHTML: {
                        __html: nextItem,
                    },
                }),
            ];
        } else {
            return [...result, nextItem];
        }
    }, [] as JSX.Element[]);
};

const RichText = ({
    content,
    className,
    theme,
    contentTheme,
    actionTheme,
    renderElement = <BodyM />,
    lightbox = false,
    l18n,
}: RichTextProps): ReactElement => {
    const children = generateContent(
        content,
        renderElement,
        theme,
        contentTheme,
        actionTheme,
        lightbox,
        l18n,
    );

    return <div className={className}>{children}</div>;
};

export default React.memo(RichText);
